<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Сохранить проект"
      next-button-text="Далее"
      back-button-text="Назад"
      class="steps-transparent mb-3"
      @on-complete="formSubmitted"
    >
      <!-- общая информация -->
      <tab-content
        title="Общая информация"
        icon="feather icon-file-text"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Общая информация
            </h5>
            <small class="text-muted">
              Введите необходимые данные.
            </small>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Название проекта"
              label-for="i-name"
            >
              <b-form-input
                id="i-name"
                v-model="projectName"
                placeholder="Напишите"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <!-- интеграции -->
      <tab-content
        title="Интеграции"
        icon="feather icon-link"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Интеграции
            </h5>
            <small class="text-muted">Добавьте интеграций в проект</small>
          </b-col>
          <b-col md="12">
            <b-form-group
              label-for="i-twitter"
            >
              <IntegrationModule
                :analytics-integrations="analyticsIntegrations"
                :traffic-integrations="trafficIntegrations"
                :ex-integrations="exIntegrations"
                :collapse-type="collapseType"
                :for-backend="false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <b-overlay
        :show="busy"
        no-wrap
        @shown="onShown"
        @hidden="onHidden"
      >
        <template v-slot:overlay>
          <div
            v-if="processing"
            class="text-center p-4 bg-primary text-light rounded"
          >
            <div class="mb-3">
              Создаем ваш проект...
            </div>
            <b-progress
              min="1"
              max="20"
              :value="counter"
              variant="success"
              height="3px"
              class="mx-n4 rounded-0"
            />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Хотите создать проект?</strong></p>
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                Отмена
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                @click="onOK"
              >
                Да
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IntegrationModule from '@/views/projects/IntegrationModule.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BOverlay,
  BIcon,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    FormWizard,
    BButton,
    BIcon,
    TabContent,
    BRow,
    BOverlay,
    BProgress,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    IntegrationModule,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      projectName: null,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      statusMapsBase: {
        0: 'SUCCESS',
        1: 'NONE',
        2: 'ERROR',
      },
      typeMapsBase: {
        0: 'YANDEX_DIRECT',
        1: 'YANDEX_METRIKA',
        2: 'GOOGLE_ANALYTICS',
        3: 'GOOGLE_ADWORDS',
      },
      exIntegrations: [
        {
          id: 1,
          name: 'Google Adwords',
          description: 'Google Adwords',
          type: 2,
          category: 'TRAFFIC',
          image: require('@/assets/images/integrations/google-ds.png'),
        },
        {
          id: 2,
          name: 'Google Analytics',
          description: 'Google Analytics',
          type: 3,
          category: 'ANALYTICS',
          image: require('@/assets/images/integrations/google-analytics.png'),
        },
        {
          id: 3,
          name: 'Yandex Direct',
          type: 0,
          category: 'TRAFFIC',
          description: 'Yandex Direct',
          image: require('@/assets/images/integrations/yandex-direct.png'),
        },
        {
          id: 4,
          name: 'Yandex Metrika',
          type: 1,
          category: 'ANALYTICS',
          description: 'Yandex Metrika',
          image: require('@/assets/images/integrations/yandex-metrika.png'),
        },
        {
          id: 5,
          name: 'VKontakte',
          type: 0,
          category: 'TRAFFIC',
          description: 'VKontakte',
          image: require('@/assets/images/integrations/vk.png'),
        }],
      trafficIntegrations: [],
      analyticsIntegrations: [],
      collapseType: 'margin',
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.counter = 1
      this.processing = true
      // Simulate an async request
      this.clearInterval()

      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 10
        }
      }, 350)

      const trafficParse = []
      const analyticsParse = []

      this.trafficIntegrations.forEach(item => {
        const integrationParse = {
          integrationType: this.typeMapsBase[item.type],
          integrationStatus: 1,
          name: item.name,
        }
        trafficParse.push(integrationParse)
      })

      this.analyticsIntegrations.forEach(item => {
        const integrationParse = {
          integrationType: this.typeMapsBase[item.type],
          integrationStatus: 1,
          name: item.name,
        }
        analyticsParse.push(integrationParse)
      })

      const project = {
        name: this.projectName,
        traffics: JSON.stringify(trafficParse),
        analytics: JSON.stringify(analyticsParse),
      }

      this.$http.post('project/save', project)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          const projectData = response.data
          this.$store.commit('project/ADD_PROJECT_IN_LIST', projectData)

          this.clearInterval()
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.busy = this.processing = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Проект успешно создан',
                icon: 'PlusIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'projects' })
          })
        })
    },
    onHidden() {
      // this.$refs.submit.focus()
    },
    formSubmitted() {
      this.processing = false
      this.busy = true
    },
  },
}
</script>
