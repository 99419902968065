<template>
  <section id="add-project">
    <b-row class="match-height">
      <b-col cols="12">
        <new-project-wizard />
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'
import NewProjectWizard from '@/views/projects/NewProjectWizard.vue'

export default {
  components: {
    NewProjectWizard,
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
